<template>
  <ion-page>
    <ion-header class="ion-padding-horizontal white-background-color">
      <ion-toolbar>
      </ion-toolbar>
      <ion-toolbar class="ion-padding-bottom">
        <z-filter @filterActived="onFilterChanged($event)" module="catalogue" />
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <section>
        <div v-if="isLoading" class="load-wrap ion-text-center">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <p>Loading...</p>
        </div>
        <z-pin-list v-if="pins.length" module="catalogue" :pins="pins" />
        <div v-if="!isLoading && pins.length == 0 && activeFilter == true"  class="ion-padding ion-text-center">There are no pins matching your search criteria.</div>
        <div v-if="!isLoading && pins.length == 0 && !activeFilter" class="ion-padding ion-text-center">There are no pins in catalogue yet.</div>

      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import {mapGetters, mapActions} from 'vuex'

  export default {

    mounted() {
      this.fetchByPage()
    },

    data() {
      return {
        activeFilter: false
      }
    },

    computed: {
      ...mapGetters('catalogue', ['pins', 'isLoading'])
    },

    methods: {
      ...mapActions('catalogue', ['fetchByPage']),

      onFilterChanged(data) {
        this.activeFilter = data.active
      }
    }
  }
</script>
<style lang="sass" scoped>
.white-background-color
  background: white
</style>
